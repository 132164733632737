<script lang="ts" setup>
import type { BlokSectionIntroduction } from '~~/types/storyblok/bloks'

const props = defineProps<{ blok: BlokSectionIntroduction }>()

const headline = computed(() => {
  return props.blok?.headline || ''
})

const useSmallType = computed(() => props.blok?.size === 'sm')

const buttonBlok = computed(() => {
  return {
    _uid: props?.blok?._uid || '',
    component: 'UiButton',
    label: 'See all',
    link: {
      ...props.blok?.link,
      id: props?.blok?.link?.id || '',
    },
    theme: 'theme-grey-100 button--outline',
  }
})
</script>

<template lang="pug">
div(
  v-editable="blok"
  v-if="headline"
  class="flex container justify-between items-center gap-18 py-48"
  :class="[props.blok?.horizontal_alignment || 'text-left']"
)
  h2(
    class="heading-1 mb-0 grow"
    :class="useSmallType ? 'text-2xl font-sans' : 'text-3xl'"
  ) {{ headline }}
  UiButton(
    v-if="buttonBlok?.link?.id"
    :blok="buttonBlok"
    class="!m-0"
  )
</template>
